import axios from 'axios';

export const state = {
  technologies: []
};

export const getters = {
  getTechnologies(state) {
    return state.technologies;
  }
};

export const actions = {
  async fetchTechnologies({ commit }, urlParams) {
    return await axios
      .get(`/technology${urlParams ? '?' : ''}${urlParams ? urlParams : ''}`)
      .then((response) => {
        if (response && response.data && response.data.data) {
          commit('setTechnologies', {
            technologies: response.data.data
          });
          return response.data.data;
        }
        return null;
      })
      .catch((error) => {
        return error;
      });
  }
};

export const mutations = {
  async setTechnologies(Store, { technologies }) {
    this.state.technologies.technologies = technologies;
  }
};
