import moment from 'moment';
import axios from 'axios';
import store from '..';
import { i18n } from '../../main';

export const state = {
  notifications: [],
  pagination: {
    per_page: 10,
    current_page: 1
  },
  only_unread_filter: false,
  dropdownNotifications: [],
  total_unread: 0
};

export const getters = {
  getNotifications(state) {
    return state.notifications;
  },
  getNotificationsPagination(state) {
    return state.pagination;
  },
  getIsNotificationsUnreadMode(state) {
    return state.only_unread_filter;
  },
  getDropdownNotifications(state) {
    return state.dropdownNotifications;
  },
  getTotalUnreadNotifications(state) {
    return state.total_unread;
  }
};

export const actions = {
  async fetchNotifications({ commit }, urlParams) {
    return await axios
      .get(`/notification${urlParams ? '?' : ''}${urlParams ? urlParams : ''}`)
      .then((response) => {
        if (response && response.data && response.data.data) {
          const notifications = response.data.data.map((notification) => {
            return {
              ...notification,
              created_at: moment(notification.created_at).format('DD/MM/YYYY'),
              read_at: notification.read_at
                ? moment(notification.read_at).format('DD/MM/YYYY')
                : null
            };
          });
          commit('setNotifications', {
            notifications
          });
          commit('setNotificationsPagination', {
            pagination: response.data.meta
          });
          return response.data.data;
        }
        return null;
      })
      .catch((error) => {
        return error;
      });
  },
  async fetchDropdownNotifications({ commit }, urlParams) {
    return await axios
      .get(`/notification${urlParams ? '?' : ''}${urlParams ? urlParams : ''}`)
      .then((response) => {
        if (response && response.data && response.data.data) {
          const notifications = response.data.data.map((notification) => {
            return {
              ...notification,
              created_at: moment(notification.created_at).format('DD/MM/YYYY')
            };
          });
          commit('setDropdownNotifications', {
            notifications
          });
          commit('setTotalUnread', {
            total_unread: response.data.total_unread
          });
          return response.data.data;
        }
        return null;
      })
      .catch((error) => {
        return error;
      });
  },
  async updateStatusOfNotification(commitObject, { notificationId, status }) {
    return await axios
      .put(`/notification/${notificationId}`, {
        status
      })
      .then((response) => {
        console.log(response);

        return null;
      })
      .catch((error) => {
        return error;
      });
  },
  async deleteNotification(commitObject, { notificationId }) {
    return await axios
      .delete(`/notification/${notificationId}`)
      .then((response) => {
        if (response && response.data) {
          store.dispatch('toggleNotification', {
            text: `${i18n.t('notification.notification_deleted')}`,
            type: '#26A06D'
          });

          return response.data.data;
        }

        return null;
      })
      .catch((error) => {
        return error;
      });
  },
  async manageIsNotificationsUnreadMode({ commit }, booleanValue) {
    commit('setIsNotificationsUnreadMode', booleanValue);
  }
};

export const mutations = {
  async setNotifications(Store, { notifications }) {
    this.state.notifications.notifications = notifications;
  },
  async setNotificationsPagination(Store, { pagination }) {
    this.state.notifications.pagination = pagination;
  },
  async setIsNotificationsUnreadMode(Store, booleanValue) {
    this.state.notifications.only_unread_filter = booleanValue;
  },
  async setDropdownNotifications(Store, { notifications }) {
    this.state.notifications.dropdownNotifications = notifications;
  },
  async setTotalUnread(Store, { total_unread }) {
    this.state.notifications.total_unread = total_unread;
  }
};
