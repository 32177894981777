import { render, staticRenderFns } from "./ProfileDropdown.vue?vue&type=template&id=19dc2282&scoped=true&"
import script from "./ProfileDropdown.vue?vue&type=script&lang=js&"
export * from "./ProfileDropdown.vue?vue&type=script&lang=js&"
import style0 from "./ProfileDropdown.vue?vue&type=style&index=0&id=19dc2282&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19dc2282",
  null
  
)

export default component.exports