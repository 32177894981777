import axios from 'axios';

export const state = {
  competences: []
};

export const getters = {
  getCompetences(state) {
    return state.competences;
  }
};

export const actions = {
  async fetchCompetences({ commit }) {
    return await axios
      .get('/competence')
      .then((response) => {
        if (response && response.data && response.data.data) {
          commit('setCompetences', response.data.data);
          return response.data.data;
        }
        return null;
      })
      .catch((error) => {
        return error;
      });
  }
};

export const mutations = {
  async setCompetences(Store, competences) {
    this.state.competences.competences = competences;
  }
};
