<template>
  <nav class="navigation">
    <ul>
      <li v-for="(item, index) in this.links" :key="`nav-link-${index}`">
        <router-link :to="item.to">
          <v-icon class="mr-4">{{ item.icon }}</v-icon>
          <span class="text mt-1">{{ item.text }}</span>
        </router-link>
      </li>
    </ul>

    <hr />

    <ul v-if="getLoggedUser">
      <li>
        <router-link to="/account-settings">
          <v-icon class="mr-4">mdi-account-edit-outline</v-icon>
          <span class="text mt-1">{{
            $t('profile_dropdown.link_account_settings')
          }}</span>
        </router-link>
      </li>

      <li>
        <router-link to="/companies">
          <v-icon class="mr-4">mdi-briefcase-outline</v-icon>
          <span class="text mt-1">{{
            $t('profile_dropdown.link_company_information')
          }}</span>
        </router-link>
      </li>

      <li>
        <a href="https://www.gotatech.team/privacy.html" target="_blank">
          <v-icon class="mr-4">mdi-file-outline</v-icon>
          <span class="text mt-1">{{
            $t('profile_dropdown.link_legal_notice')
          }}</span>
        </a>
      </li>

      <li v-if="getLoggedUser && getLoggedUser.email_verified_at === null">
        <a href="" @click.prevent="resendVerification">
          <v-icon>mdi-shield-check</v-icon>
          <span>{{ $t('profile_dropdown.link_resend_verification') }}</span>
        </a>
      </li>

      <li>
        <a href="" @click.prevent="logout">
          <v-icon class="mr-4">mdi-logout</v-icon>
          <span class="text mt-1">{{
            $t('profile_dropdown.link_sign_out')
          }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'NavigationMobile',

  data() {
    return {};
  },
  watch: {
    $route() {
      this.$emit('closeMenu');
    }
  },
  computed: {
    getLoggedUser() {
      return this.$store.getters.fullUserInfo;
    },
    links() {
      return [
        {
          to: '/',
          text: `${this.$t('navigation.dashboard')}`,
          icon: 'mdi-monitor-dashboard'
        },
        {
          to: '/projects',
          text: `${this.$t('navigation.projects')}`,
          icon: 'mdi-bookmark-box-multiple-outline'
        },
        {
          to: '/team',
          text: `${this.$t('navigation.team')}`,
          icon: 'mdi-account-group-outline'
        },
        {
          to: '/applications',
          text: `${this.$t('navigation.applications')}`,
          icon: 'mdi-chart-areaspline'
        }
      ];
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logoutAction');
      this.$router.push('login');
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation {
  width: 100%;
  position: relative;
  // background: #4c2e6a;
  color: #000;
  padding: 0;
  display: flex;
  flex-direction: column;

  ul {
    padding-left: 0;
    list-style-type: none;
    position: relative;
  }
  .router-link-exact-active {
    //.router-link-active {
    background: #4c2e6a;

    color: #fff;
    i {
      color: #fff;
    }
  }
  ul > li.active .dropdown {
    visibility: visible;
    opacity: 1;
  }

  ul > li > a {
    display: block;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #000;
    text-decoration: none;
    .holder {
      text-align: center;
    }

    i {
      display: block;
      color: #000;
    }
    span.text {
      display: block;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  ul + ul {
    margin-top: auto;
  }
}
</style>
