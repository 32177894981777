import { getFormData, POST, PUT } from '@/utils/axios';
import axios from 'axios';
import store from '..';
import moment from 'moment';
//import router from '@/router';
//import { i18n } from '../../main';

export const state = {
  team: [],
  pagination: {}
};

export const getters = {
  getTeam(state) {
    return state.team;
  },
  getTeamPagination(state) {
    return state.pagination;
  }
};

export const actions = {
  async fetchTeam({ commit }, urlParams) {
    return await axios
      .get(`resource${urlParams ? '?' : ''}${urlParams ? urlParams : ''}`)
      .then((response) => {
        if (response.data) {
          const team = response.data.data.map((member) => {
            return {
              ...member,
              available_from: member.available_from
                ? moment(member.available_from).format('DD/MM/YYYY')
                : 'ASAP',
              created_at: moment(member.created_at).format('DD/MM/YYYY')
            };
          });
          commit('setTeam', {
            team
          });
          commit('setTeamPagination', {
            pagination: response.data.meta
          });
        }

        return response;
      })
      .catch(() => {});
  },
  async fetchTeamMemberById(commitObject, id) {
    return await axios
      .get(`resource/${id}`)
      .then(async (response) => {
        if (response.data && response.data.data) {
          return await axios
            .get(`/resource/${id}/resource_media`, {
              pageSize: 99999,
              page: 1
            })
            .then((responseMedia) => {
              if (
                responseMedia &&
                responseMedia.data &&
                responseMedia.data.data
              ) {
                return {
                  ...response.data.data,
                  files: responseMedia.data.data
                };
              } else return response.data.data;
            });
        }

        return response;
      })
      .catch(() => {});
  },
  async createTeamMember({ commit }, payload) {
    if (typeof payload.avatar !== 'object' && payload.avatar !== '')
      delete payload.avatar;

    return await axios
      .post(`/resource`, getFormData(payload, POST), {
        ContentType: 'multipart/form-data',
        errorHandle: false
      })
      .then(async (response) => {
        if (response && response.data && response.data.data) {
          commit('createMember', {
            member: response.data.data
          });

          if (payload.files.length) {
            for await (const [, file] of payload.files.entries()) {
              axios.post(
                `/resource/${response.data.data.id}/resource_media`,
                getFormData({ file }, POST),
                {
                  ContentType: 'multipart/form-data'
                }
              );
            }
          }

          //           store.dispatch('toggleNotification', {
          //             text: `${i18n.t('notification.team_member_created')}`,
          //             type: '#26A06D'
          //           });
          //
          //           router.push(`/team/view/${response.data.data.id}`);
        }

        return response;
      })
      .catch((e) => {
        if (e && e.response && e.response.data && e.response.data.errors) {
          store.dispatch(
            'setValidationErrors',
            Object.keys(e.response.data.errors)
          );
          store.dispatch('toggleNotification', {
            text: `${e.response.data.message}`,
            type: '#AE0A0A'
          });
        }
      });
  },
  async editTeamMember(commitObject, payload) {
    if (typeof payload.avatar !== 'object' && payload.avatar !== '')
      delete payload.avatar;

    return await axios
      .post(`/resource/${payload.id}`, getFormData(payload, PUT), {
        ContentType: 'multipart/form-data',
        errorHandle: false
      })
      .then(async (response) => {
        if (response && response.data && response.data.data) {
          if (payload.files.length) {
            for await (const [, file] of payload.files.entries()) {
              axios.post(
                `/resource/${response.data.data.id}/resource_media`,
                getFormData({ file }, POST),
                {
                  ContentType: 'multipart/form-data'
                }
              );
            }
          }

          if (payload.deletedFileIndexes.length) {
            for await (const [, file] of payload.deletedFileIndexes.entries()) {
              axios.delete(
                `/resource/${response.data.data.id}/resource_media/${file}`
              );
            }
          }

          //           store.dispatch('toggleNotification', {
          //             text: `${i18n.t('notification.team_member_edited')}`,
          //             type: '#26A06D'
          //           });
          //
          //           router.push(`/team/view/${response.data.data.id}`);
        }
        return response;
      })
      .catch((e) => {
        if (e && e.response && e.response.data && e.response.data.errors) {
          store.dispatch(
            'setValidationErrors',
            Object.keys(e.response.data.errors)
          );
          store.dispatch('toggleNotification', {
            text: `${e.response.data.message}`,
            type: '#AE0A0A'
          });
        }
      });
  },
  async deleteTeamMember(commitObject, id) {
    return await axios
      .delete(`/resource/${id}`)
      .then((response) => {
        return response;
      })
      .catch(() => {});
  }
};

export const mutations = {
  setTeam(Store, { team }) {
    this.state.team.team = team;
  },
  createMember(Store, { member }) {
    this.state.team.team.push(member);
  },
  setTeamPagination(Store, { pagination }) {
    this.state.team.pagination = pagination;
  }
};
