import axios from 'axios';
import moment from 'moment';
import store from '..';
import router from '@/router';
import { i18n } from '../../main';

export const state = {
  applications: [],
  pagination: {}
};

export const getters = {
  getApplications(state) {
    return state.applications;
  },
  getApplicationsPagination(state) {
    return state.pagination;
  }
};

export const actions = {
  async fetchApplications({ commit }, urlParams) {
    return await axios
      .get(
        `project_application${urlParams ? '?' : ''}${
          urlParams ? urlParams : ''
        }`
      )
      .then((response) => {
        if (response.data) {
          const applications = response.data.data.map((application) => {
            return {
              ...application,
              created_at: moment(application.created_at).format('DD/MM/YYYY')
            };
          });
          commit('setApplications', {
            applications
          });
          commit('setApplicationsPagination', {
            pagination: response.data.meta
          });
        }

        return response;
      })
      .catch(() => {});
  },
  async fetchApplication(commitObject, { projectId, applicationId }) {
    return await axios
      .get(`project/${projectId}/project_application/${applicationId}`)
      .then((response) => {
        if (response.data) {
          //
        }

        return response;
      })
      .catch(() => {});
  },
  async createApplication(commitObject, { projectId, resources }) {
    return await axios
      .post(`project/${projectId}/project_application`, {
        resources
      })
      .then((response) => {
        if (response.data) {
          store.dispatch('toggleNotification', {
            text: `${i18n.t('notification.application_created')}`,
            type: '#26A06D'
          });
        }
        router.push(
          `/applications/${response.data.data.id}/project/${response.data.data.project_id}`
        );
        return response;
      })
      .catch(() => {});
  },
  async deleteApplication(commitObject, { projectId, applicationId }) {
    return await axios
      .delete(`project/${projectId}/project_application/${applicationId}`)
      .then((response) => {
        if (response.data) {
          store.dispatch('toggleNotification', {
            text: `${i18n.t('notification.application_deleted')}`,
            type: '#26A06D'
          });
        }

        return response;
      })
      .catch(() => {});
  },
  async updateApplication(
    commitObject,
    { projectId, applicationId, resources }
  ) {
    return await axios
      .put(`project/${projectId}/project_application/${applicationId}`, {
        resources
      })
      .then((response) => {
        if (response.data) {
          store.dispatch('toggleNotification', {
            text: `${i18n.t('notification.application_updated')}`,
            type: '#26A06D'
          });
        }

        return response;
      })
      .catch(() => {});
  },
  async updateApplicationStatus(
    commitObject,
    { projectId, applicationId, status }
  ) {
    return await axios
      .put(`project/${projectId}/project_application/${applicationId}`, {
        status
      })
      .then((response) => {
        if (response.data) {
          store.dispatch('toggleNotification', {
            text: `${i18n.t('notification.application_status_updated')}`,
            type: '#26A06D'
          });
        }

        return response;
      })
      .catch(() => {});
  }
};

export const mutations = {
  setApplications(Store, { applications }) {
    this.state.applications.applications = applications;
  },
  setApplicationsPagination(Store, { pagination }) {
    this.state.applications.pagination = pagination;
  },
  createApplication(Store, { application }) {
    this.state.applications.applications.push(application);
  }
};
