<template>
  <nav class="navigation-holder sticky-top" ref="navHolder">
    <v-navigation-drawer
      v-model="drawer"
      permanent
      :expand-on-hover="!isMenuFixed"
      class=""
      ref="nav"
      @update:mini-variant="setNavCollapse"
      @mouseover.native="setNavExpand"
    >
      <v-list nav dense>
        <div class="nav-header">
          <v-list-item class="logo-holder">
            <v-list-item-icon class="cursor-pointer" @click.stop="logoClick">
              <img :src="logoToDisplay" alt="" style="max-width: 136px" />
            </v-list-item-icon>

            <v-list-item-title class="button-holder">
              <button class="menuToggle" @click.stop="toggleSideNavigation">
                <span v-if="isMenuFixed"></span>
              </button>
            </v-list-item-title>
          </v-list-item>

          <!-- <v-list-item class="button-holder">
            <button class="menuToggle" @click="toggleSideNavigation">
              <span v-if="isMenuFixed"></span>
            </button>
          </v-list-item> -->
        </div>
        <v-list-item
          link
          v-for="(item, index) in this.links"
          :to="item.to"
          :key="`desktop-nav-links-${index}`"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          id="feedback_jotform"
          class="btn lightbox-230261934963358 feedback"
        >
          <v-list-item-icon>
            <v-icon>mdi-chat</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Feedback</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
//import Logo from '@/assets/images/logo.svg';
import Logo from '@/assets/images/logo-xs.svg';
import LogoExpand from '@/assets/images/logo-expand.svg';
export default {
  name: 'SideNavigation',
  data() {
    return {
      appLogo: Logo,
      scroll: 0,
      drawer: true,
      mini: true,
      isCollapsed: true
    };
  },
  computed: {
    links() {
      return [
        {
          to: '/',
          text: `${this.$t('navigation.dashboard')}`,
          icon: 'mdi-monitor-dashboard'
        },
        {
          to: '/projects',
          text: `${this.$t('navigation.projects')}`,
          icon: 'mdi-bookmark-box-multiple-outline'
        },
        {
          to: '/team',
          text: `${this.$t('navigation.team')}`,
          icon: 'mdi-account-group-outline'
        },
        {
          to: '/applications',
          text: `${this.$t('navigation.applications')}`,
          icon: 'mdi-chart-areaspline'
        }
      ];
    },
    isMenuFixed() {
      return this.$store.getters.isMenuFixed;
    },
    logoToDisplay() {
      if (this.isMenuFixed) {
        return LogoExpand;
      } else {
        if (this.isCollapsed) {
          return Logo;
        } else {
          return LogoExpand;
        }
      }
    }
  },
  methods: {
    toggleSideNavigation() {
      this.$store.dispatch('toggleMenu');
    },
    logoClick() {
      if (this.$route.path !== '/') this.$router.push('/');
      //this.$router.push('/');
    },
    setNavCollapse() {
      this.isCollapsed = true;
    },
    setNavExpand() {
      this.isCollapsed = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', function () {});
  },
  mounted() {
    if (window.JotformFeedback) {
      new window.JotformFeedback({
        formId: '230261934963358',
        base: 'https://form.jotform.com/',
        windowTitle: 'Feedback Form',
        background: '#9013fe',
        fontColor: '#FFFFFF',
        type: '2',
        height: 500,
        width: 700,
        openOnLoad: false
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation {
  width: 76px;
  position: relative;
  background: #4c2e6a;
  color: #fff;
  padding: 0;
  display: flex;
  flex-direction: column;

  ul {
    padding-left: 0;
    list-style-type: none;
    position: relative;
  }
  .router-link-exact-active,
  .router-link-active {
    background: #ff7110;
  }
  ul > li.active .dropdown {
    visibility: visible;
    opacity: 1;
  }

  ul > li > a {
    width: 76px;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-decoration: none;
    .holder {
      text-align: center;
    }

    i {
      display: block;
    }
    span.text {
      display: block;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  & > ul:last-of-type:before,
  & > ul:first-of-type:after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    background: #ff7110;
    height: 1px;
    width: 36px;
  }

  & > ul:first-of-type:after {
    bottom: 0;
  }

  & > ul:last-of-type:before {
    top: 0;
  }

  ul + ul {
    margin-top: auto;
  }
  .dropdown {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    background: blue;
    width: 240px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.4s, visibility 0.4s;
  }
}

.navigation-holder {
  padding: 0;
  background: #4c2e6a;
  color: #fff;
  //height: calc(100vh - 100px);
  max-height: 100vh;

  .theme--light.v-navigation-drawer {
    background: #4c2e6a;
    color: #fff;
  }

  //.v-list-item:hover,
  .v-list-item--active {
    background: #ff6700;
  }

  .theme--light.v-icon,
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
  .theme--light.v-list {
    color: #fff;
  }
  .v-list--dense .v-list-item .v-list-item__subtitle,
  .v-list--dense .v-list-item .v-list-item__title,
  .v-list-item--dense .v-list-item__subtitle,
  .v-list-item--dense .v-list-item__title {
    font-size: 15px;
    font-weight: 500;
  }
  .v-list-item__action:first-child,
  .v-list-item__icon:first-child {
    margin-right: 13px;
  }

  .feedback {
    cursor: pointer;
  }
}
.sticky-top {
  position: sticky;
  top: 0;
  bottom: 0;
}
</style>

<style lang="scss">
.v-list.v-list--nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  & > a {
    flex: 0 1;
  }
  .logo-holder {
    margin-top: 0 !important;
    // background: #fff;
    //flex: 0 0;
    background: none !important;
    &.v-list-item--active {
      background: none !important;

      &:before {
        display: none;
      }
    }
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0 !important;
  }
  .menuToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    border: 2px solid #fff;
    background: none;
    margin-left: auto;

    &:focus-visible {
      outline: none;
    }

    span {
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 50%;
    }
  }

  .button-holder {
    width: 20px !important;
    height: 20px !important;
    // flex: 0 0;
    padding: 0;
    margin-left: -10px;
  }

  & > div {
    flex: 0 1;
    margin-top: auto;
  }
}
</style>
