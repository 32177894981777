export const state = {
  validationErrors: []
};

export const getters = {
  getFormValidationErrors(state) {
    return state.validationErrors;
  }
};

export const actions = {
  async setValidationErrors({ commit }, errors) {
    commit('setValidationErrors', errors);
  },
  async setValidationError({ commit }, error) {
    commit('setValidationError', error);
  },
  async resetValidationError({ commit }, field) {
    commit('resetValidationError', field);
  }
};

export const mutations = {
  setValidationErrors(Store, errors) {
    this.state.formValidationErrors.validationErrors = errors;
  },
  setValidationError(Store, error) {
    this.state.formValidationErrors.validationErrors = [
      ...this.state.formValidationErrors.validationErrors,
      error
    ];
  },
  resetValidationError(Store, error) {
    console.log(this.state.formValidationErrors.validationErrors);
    this.state.formValidationErrors.validationErrors =
      this.state.formValidationErrors.validationErrors.filter(
        (field) => field !== error
      );
  }
};
