import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    meta: {
      public: false
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue')
  },
  {
    path: '/email/verify/:id/:hash',
    name: 'verification',
    meta: {
      public: true,
      verification: true
    },
    component: () =>
      import(/* webpackChunkName: "verification" */ '../views/LoginView.vue')
  },
  {
    path: '/reset-password',
    name: 'screen_password_reset',
    meta: {
      public: true,
      resetPassword: true
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/LoginView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      public: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  // {
  //   path: '/login-sso',
  //   name: 'login-sso',
  //   meta: {
  //     public: true
  //   },
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../views/LoginSSOView.vue')
  // },
  {
    path: '/404',
    name: 'not-found',
    meta: {
      public: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/NotFoundView.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    meta: {
      public: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/RegistrationView.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: {
      public: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ForgotPasswordView.vue')
  },

  {
    path: '/projects',
    meta: {
      public: false
    },
    name: 'projects',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ProjectsView.vue')
  },
  {
    path: '/projects/edit/:id',
    meta: {
      public: false
    },
    name: 'project_edit_view',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ProjectsCreateEdit.vue')
  },
  {
    path: '/projects/create',
    meta: {
      public: false
    },
    name: 'project_create_view',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ProjectsCreateEdit.vue')
  },
  {
    path: '/projects/:id',
    meta: {
      public: false
    },
    name: 'projects_view',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ProjectsSingle.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      public: false
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/UsersView.vue')
  },
  {
    path: '/legal/terms-of-service',
    name: 'legal-terms',
    meta: {
      public: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/Legal/LegalTermsView.vue'
      )
  },
  {
    path: '/legal/service-level-agreement',
    name: 'legal-service-agreement',
    meta: {
      public: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/Legal/LegalServiceAgreementView.vue'
      )
  },
  {
    path: '/legal/privacy',
    name: 'legal-privacy',
    meta: {
      public: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/Legal/LegalPrivacyView.vue'
      )
  },
  {
    path: '/legal/copyright-policy',
    name: 'legal-copyright',
    meta: {
      public: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/Legal/LegalCopyrightView.vue'
      )
  },
  {
    path: '/legal/cookie-policy',
    name: 'legal-cookie',
    meta: {
      public: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/Legal/LegalCookieView.vue'
      )
  },
  {
    path: '/legal/acceptable-use-policy',
    name: 'legal-acceptable-policy',
    meta: {
      public: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/Legal/LegalAcceptablePolicyView.vue'
      )
  },
  {
    path: '/account-settings',
    name: 'account-settings',
    meta: {
      public: false
    },
    component: () =>
      import(
        /* webpackChunkName: "account-settings" */ '../views/AccountSettingsView.vue'
      )
  },
  {
    path: '/companies',
    name: 'companies',
    meta: {
      public: false
    },
    component: () =>
      import(/* webpackChunkName: "companies" */ '../views/CompaniesView.vue')
  },
  {
    path: '/companies/:companyId',
    name: 'company',
    meta: {
      public: false
    },
    component: () =>
      import(/* webpackChunkName: "company" */ '../views/CompanyView.vue')
  },
  {
    path: '/create-company',
    name: 'create-company',
    meta: {
      public: false,
      create: true
    },
    component: () =>
      import(
        /* webpackChunkName: "create-company" */ '../views/CreateEditCompanyView.vue'
      )
  },
  {
    path: '/edit-company/:companyId',
    name: 'edit-company',
    meta: {
      public: false,
      edit: true
    },
    component: () =>
      import(
        /* webpackChunkName: "edit-company" */ '../views/CreateEditCompanyView.vue'
      )
  },
  {
    path: '/team',
    name: 'team',
    meta: {
      public: false
    },
    component: () =>
      import(/* webpackChunkName: "team" */ '../views/TeamView.vue')
  },
  {
    path: '/team/view/:teamMemberId',
    name: 'team-member',
    meta: {
      public: true
    },
    component: () =>
      import(
        /* webpackChunkName: "team-member" */ '../views/TeamMemberView.vue'
      )
  },
  {
    path: '/team/add-member',
    name: 'add-member',
    meta: {
      public: false,
      create: true
    },
    component: () =>
      import(
        /* webpackChunkName: "add-member" */ '../views/CreateEditTeamMemberView.vue'
      )
  },
  {
    path: '/team/edit-member/:teamMemberId',
    name: 'edit-member',
    meta: {
      public: false,
      edit: true
    },
    component: () =>
      import(
        /* webpackChunkName: "edit-member" */ '../views/CreateEditTeamMemberView.vue'
      )
  },
  {
    path: '/applications',
    name: 'applications',
    meta: {
      public: false
    },
    component: () =>
      import(
        /* webpackChunkName: "applications" */ '../views/ApplicationsView.vue'
      )
  },
  {
    path: '/applications/:projectId',
    name: 'pending-applications',
    meta: {
      public: false
    },
    component: () =>
      import(
        /* webpackChunkName: "pending-applications" */ '../views/ApplicationsView.vue'
      )
  },
  {
    path: '/applications/:applicationId/project/:projectId',
    name: 'application',
    meta: {
      public: false
    },
    component: () =>
      import(
        /* webpackChunkName: "application" */ '../views/ApplicationView.vue'
      )
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: {
      public: false
    },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ '../views/NotificationsView.vue'
      )
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      public: false
    },
    component: () =>
      import(/* webpackChunkName: "search" */ '../views/SearchView.vue')
  },
  {
    path: '*',
    meta: {
      public: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/NotFoundView.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const { isAuthenticated } = store.getters.identity;

  if (isAuthenticated) {
    store.dispatch(
      'fetchDropdownNotifications',
      'page=1&pageSize=5&status=unread'
    );
  }

  // if (
  //   !isAuthenticated &&
  //   to.name !== 'login' &&
  //   to.name !== 'registration' &&
  //   to.name !== 'registration-confirmed'
  // ) {
  //   localStorage.setItem('lastUserPath', to.path);
  // }

  if (
    to.name !== 'login' &&
    to.path !== '/login' &&
    !to.meta.public &&
    !isAuthenticated
  ) {
    //localStorage.setItem('lastUserPath', to.path);
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router;
