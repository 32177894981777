import axios from 'axios';
import store from '..';
import { getFormData, POST, PUT } from '@/utils/axios';

export const state = {
  projects: [],
  pagination: {},
  project: {}
};

export const getters = {
  getAllProjects(state) {
    return state.projects;
  },
  getAllProjectsPagination(state) {
    return state.pagination;
  },
  getCurrentProject(state) {
    return state.project;
  }
  // getTotalProjectsCount(state) {
  //   return state.totalProjectsNumber;
  // }
};

export const actions = {
  async fetchAllProjects({ commit }, urlParams) {
    return await axios
      .get(`project${urlParams ? '?' : ''}${urlParams ? urlParams : ''}`)
      .then((response) => {
        if (response.data) {
          commit('setProjects', {
            projects: response.data.data
          });
          // commit('setProjectsNumber', {
          //   totalProjectsNumber: response.data.meta.total
          // });
          commit('setProjectsPagination', {
            pagination: response.data.meta
          });
        }

        return response;
      })
      .catch((error) => {
        console.log(error);
        // handle this error here
        console.warn('Not good man :(');
      });
  },
  async fetchSingleProject({ commit, dispatch }, urlParams) {
    return await axios
      .get(`project/${urlParams}`)
      .then((response) => {
        if (response.data) {
          dispatch('getProjectFiles', {
            id: response.data.data.id
          });

          commit('setProject', {
            project: response.data.data
          });
        }

        return response;
      })
      .catch((error) => {
        console.log(error);
        // handle this error here
        console.warn('Not good man :(');
      });
  },
  async getProjectFiles({ commit }, payload) {
    return await axios
      .get(`project/${payload.id}/project_media`)
      .then((response) => {
        if (response.data) {
          commit('setProjectMedia', {
            id: payload.id,
            files: response.data.data
          });
        }

        return response;
      })
      .catch((error) => {
        console.log(error);
        // handle this error here
        console.warn('Not good man :(');
      });
  },
  async updateProjectImage({ commit }, { id, image }) {
    return await axios
      .post(
        `project/${id}`,
        getFormData(
          {
            image
          },
          PUT
        ),
        {
          ContentType: 'multipart/form-data'
        }
      )
      .then((response) => {
        if (response.data) {
          //console.log(response);

          commit('updateProject', {
            project: response.data.data
          });
        }

        return response;
      })
      .catch((error) => {
        console.log(error);
        // handle this error here
        console.warn('Not good man :(');
      });
  },
  async updateProjectFile({ commit }, { id, file }) {
    return await axios
      .post(
        `project/${id}/project_media`,
        getFormData(
          {
            file
          },
          POST
        ),
        {
          ContentType: 'multipart/form-data'
        }
      )
      .then((response) => {
        if (response.data) {
          commit('updateProject', {
            project: response.data.data
          });
        }

        return response;
      })
      .catch((error) => {
        console.log(error);
        // handle this error here
        console.warn('Not good man :(');
      });
  },
  async deleteProjectFile({ commit }, payload) {
    return await axios
      .delete(`project/${payload.id}/project_media/${payload.fileId}`)
      .then((response) => {
        commit('deleteProjectFile', {
          //projectId: payload.id,
          fileId: payload.fileId
        });

        return response;
      })
      .catch((error) => {
        console.log(error);
        // handle this error here
        console.warn('Not good man :(');
      });
  },

  async addProject({ commit, dispatch }, payload) {
    const image = payload.image;
    delete payload.image;
    const files = payload.files;
    delete payload.files;

    return await axios
      .post(
        'project',
        { ...payload },
        {
          errorHandle: false
        }
      )
      .then((response) => {
        if (response.data) {
          if (image && typeof image !== 'string') {
            dispatch('updateProjectImage', {
              id: response.data.data.id,
              image
            });
          }

          if (files.length) {
            for (var i = files.length - 1; i >= 0; i--) {
              dispatch('updateProjectFile', {
                id: response.data.data.id,
                file: files[i]
              });
            }
          }

          commit('addProject', {
            project: response.data.data
          });
        }

        return response;
      })
      .catch((e) => {
        if (e && e.response && e.response.data && e.response.data.errors) {
          store.dispatch(
            'setValidationErrors',
            Object.keys(e.response.data.errors)
          );
          store.dispatch('toggleNotification', {
            text: `${e.response.data.message}`,
            type: '#AE0A0A'
          });
        }
      });
  },
  async updateProject({ commit, dispatch }, { payload, id }) {
    if (!payload.image) {
      payload.image = '';
    }

    const image = payload.image;
    delete payload.image;
    const files = payload.files;
    delete payload.files;
    const deletedFileIndexes = payload.deletedFileIndexes;
    delete payload.deletedFileIndexes;

    return await axios
      .put(
        `project/${id}`,
        { ...payload },
        {
          errorHandle: false
        }
      )
      .then((response) => {
        if (response.data) {
          if ((image && typeof image !== 'string') || image === '') {
            dispatch('updateProjectImage', { id, image });
          }

          if (files.length) {
            for (var i = files.length - 1; i >= 0; i--) {
              dispatch('updateProjectFile', {
                id: response.data.data.id,
                file: files[i]
              });
            }
          }

          if (deletedFileIndexes.length) {
            for (var j = deletedFileIndexes.length - 1; j >= 0; j--) {
              dispatch('deleteProjectFile', {
                id: response.data.data.id,
                fileId: deletedFileIndexes[j]
              });
            }
          }

          commit('updateProject', {
            project: response.data.data
          });
        }

        return response;
      })
      .catch((e) => {
        if (e && e.response && e.response.data && e.response.data.errors) {
          store.dispatch(
            'setValidationErrors',
            Object.keys(e.response.data.errors)
          );
          store.dispatch('toggleNotification', {
            text: `${e.response.data.message}`,
            type: '#AE0A0A'
          });
        }
      });
  },

  // Todo fix this
  async deleteProject({ commit }, { id }) {
    console.log(id);
    //, { errorHandle: false }
    return await axios
      .delete(`project/${id}`)
      .then((response) => {
        commit('deleteProject', { project: response.data.data });

        return response;
      })
      .catch((error) => {
        console.log(error);
        // handle this error here
        console.warn('Not good man :(');
      });
  }
};

export const mutations = {
  setProjects(Store, { projects }) {
    this.state.projects.projects = projects;
  },
  setProject(Store, { project }) {
    this.state.projects.project = project;
  },
  setProjectMedia(Store, { files }) {
    //     const index = this.state.projects.projects.indexOf(
    //       (item) => item.id === id
    //     );
    //
    //     if (index !== -1) {
    //       this.state.projects.projects[index].files = files;
    //     }
    this.state.projects.project.files = files;
  },
  // setProjectsNumber(Store, { totalProjectsNumber }) {
  //   this.state.projects.totalProjectsNumber = totalProjectsNumber;
  // },
  setProjectsPagination(Store, { pagination }) {
    this.state.projects.pagination = pagination;
  },
  // incrementProjectsNumber(Store) {
  //   this.state.projects.totalProjectsNumber =
  //     this.state.projects.totalProjectsNumber + 1;
  // },
  addProject(Store, { project }) {
    this.state.projects.projects.push(project);
  },
  updateProject(Store, { project }) {
    const index = this.state.projects.projects.indexOf(
      (item) => item.id === project.id
    );

    if (index !== -1) {
      this.state.projects.projects[index] = project;
    }
  },
  deleteProject(Store, { project }) {
    const projectsCopy = this.state.projects.projects;
    const index = projectsCopy.findIndex((item) => item.id === project.id);

    projectsCopy.splice(index, 1);
    this.state.projects.projects = projectsCopy;
  },
  deleteProjectFile(Store, { fileId }) {
    const filesCopy = this.state.projects.project.files;
    const fileIndex = filesCopy.findIndex((item) => item.id === fileId);
    filesCopy.splice(fileIndex, 1);
    this.state.projects.project.files = filesCopy;
  }
};
