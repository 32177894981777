import axios from 'axios';

export const state = {
  countries: [],
  regions: []
};

export const getters = {
  getCountries(state) {
    return state.countries;
  },
  getRegions(state) {
    return state.regions;
  }
};

export const actions = {
  async fetchRegions({ commit }) {
    return await axios
      .get('/region')
      .then((response) => {
        console.log(response);
        if (response && response.data && response.data.data) {
          commit('setRegions', response.data.data);
          return response.data.data;
        }
        return null;
      })
      .catch((error) => {
        return error;
      });
  },
  async fetchCountries({ commit }, regionId) {
    return await axios
      .get('/country', {
        params: {
          region: regionId,
          pageSize: 99999,
          orderByField: 'name'
        }
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          commit('setCountries', response.data.data);
          return response.data.data;
        }
        return null;
      })
      .catch((error) => {
        return error;
      });
  }
};

export const mutations = {
  async setRegions(Store, regions) {
    this.state.regions = regions;
  },
  async setCountries(Store, countries) {
    this.state.countries = countries;
  }
};
