<template>
  <div class="notification-dropdown-parent">
    <v-menu offset-y content-class="notification-dropdown-wrap">
      <template v-slot:activator="{ on, attrs }">
        <div class="image" v-bind="attrs" v-on="on">
          <v-badge
            class="notification-dropdown-alert"
            overlap
            dot
            offset-x="20"
            offset-y="20"
            color="error"
            :value="totalUnreadNotifications"
          >
            <v-avatar
              color="#F5F6FD"
              :size="$vuetify.breakpoint.xs ? '40' : '46'"
            >
              <v-icon color="#4C2E6A" size="26"> mdi-bell-outline </v-icon>
            </v-avatar>
          </v-badge>
        </div>
      </template>
      <v-list class="notification-dropdown">
        <div
          class="group group--info d-flex justify-space-between align-center"
        >
          <h5>{{ $t('notification_dropdown.title') }}</h5>

          <router-link to="/notifications">
            <v-chip
              class="chip-notification-counter"
              @click="() => setIsNotificationsUnreadMode(true)"
            >
              {{ totalUnreadNotifications }}
              {{ $t('notification_dropdown.new') }}</v-chip
            >
          </router-link>
        </div>

        <div
          v-for="(
            notification, notificationIndex
          ) in dropdownNotificationsFromStore"
          class="group group--notifications"
          :key="notificationIndex"
          @click.prevent="(e) => markAsRead(e, notification.id)"
        >
          <ul>
            <li
              :class="`group group--notifications ${
                notification.read_at && 'readed'
              }`"
            >
              <p v-html="notification.data"></p>

              <span>{{ notification.created_at }}</span>
            </li>
          </ul>
        </div>

        <div class="group group--links d-flex justify-end align-center">
          <router-link to="/notifications">
            <div @click="() => setIsNotificationsUnreadMode(false)">
              <span>{{ $t('notification_dropdown.view') }}</span>

              <v-icon class="ml-2">mdi-arrow-right</v-icon>
            </div>
          </router-link>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'NotificationDropdown',

  data() {
    return {};
  },
  computed: {
    getLoggedUser() {
      return this.$store.getters.fullUserInfo;
    },
    dropdownNotificationsFromStore() {
      return this.$store.getters.getDropdownNotifications;
    },
    pagination() {
      return this.$store.getters.getNotificationsPagination;
    },
    getIsNotificationsUnreadMode() {
      return this.$store.getters.getIsNotificationsUnreadMode;
    },
    totalUnreadNotifications() {
      return this.$store.getters.getTotalUnreadNotifications;
    }
  },
  methods: {
    async fetchDropdownNotifications() {
      return await this.$store.dispatch(
        'fetchDropdownNotifications',
        'page=1&pageSize=5&status=unread'
      );
    },
    async fetchNotifications() {
      const { per_page, current_page } = this.pagination;

      return await this.$store
        .dispatch(
          'fetchNotifications',
          `pageSize=${per_page}&page=${current_page}${
            this.getNotificationsOnlyUnreadFilter ? '&status=unread' : ''
          }`
        )
        .then(() => {
          return;
        });
    },
    markAsRead(event, id) {
      const hrefFromChild = event.target.getAttribute('href');

      const isNotificationAlreadyRead =
        this.dropdownNotificationsFromStore.find(
          (notification) => notification.id === id
        ).read_at;

      if (!isNotificationAlreadyRead) {
        this.$store
          .dispatch('updateStatusOfNotification', {
            notificationId: id,
            status: 'read'
          })
          .then(() => {
            this.fetchNotifications().then(() => {
              this.fetchDropdownNotifications().then(() => {
                if (hrefFromChild) window.location.assign(hrefFromChild);
              });
            });
          });
      } else if (hrefFromChild) window.location.assign(hrefFromChild);
    },
    setIsNotificationsUnreadMode(booleanValue) {
      this.$store.dispatch('manageIsNotificationsUnreadMode', booleanValue);
    }
  },
  mounted() {
    this.fetchDropdownNotifications();
  }
};
</script>

<style lang="scss" scoped>
.notification-dropdown {
  width: 280px;
  border: 1px solid #5e3bc2;
  border-radius: 6px;

  a {
    text-decoration: none;
  }

  .chip-notification-counter {
    color: #ff6700 !important;
    border: 1px solid #ff6700 !important;
    background: #fff9ed !important;
    cursor: pointer;
  }
}

.group + .group {
  border-top: 1px solid #b6b6b6;
}

.group--notifications {
  padding: 8px 0;
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  li {
    display: block;
    text-decoration: none;
    background: #f5f6fd;
    &.readed {
      background: white;
    }
    font-size: 15px;
    color: #000;
    line-height: 1.33;
    padding: 6px 17px;
    cursor: pointer;
    p {
      margin-bottom: 0;
      line-height: 1.2;
      font-size: 15px;
    }

    span {
      font-size: 13px;
      display: block;
      color: #969696;
      font-weight: 400;
    }
  }
  i {
    margin-right: 10px;
  }
}

.group--info {
  padding: 10px 18px;
}

.group--links {
  padding: 14px 14px 8px;
  a {
    text-decoration: none;
    i {
      color: #000;
    }
    span {
      color: #5e3bc2;
      font-size: 15px;
      text-decoration: underline;
    }
  }
}
</style>

<style lang="scss">
.notification-dropdown-wrap {
  max-width: 100%;
}

.notification-dropdown-alert > .v-badge__wrapper > .v-badge__badge {
  width: 15px;
  height: 15px;
  border-radius: 50px;
}
</style>
