import { getFormData, POST, PUT } from '@/utils/axios';
import axios from 'axios';
import store from '..';
//import router from '@/router';
//import { i18n } from '../../main';

export const state = {
  companies: [],
  pagination: {}
};

export const getters = {
  getAllCompanies(state) {
    return state.companies;
  },
  getAllCompaniesPagination(state) {
    return state.pagination;
  }
};

export const actions = {
  async fetchAllCompanies({ commit }, urlParams) {
    return await axios
      .get(`company${urlParams ? '?' : ''}${urlParams ? urlParams : ''}`)
      .then((response) => {
        if (response.data) {
          commit('setCompanies', {
            companies: response.data.data
          });
          commit('setCompaniesPagination', {
            pagination: response.data.meta
          });
        }

        return response;
      })
      .catch(() => {});
  },
  async fetchCompanyById(commitObject, id) {
    return await axios
      .get(`company/${id}`)
      .then((response) => {
        if (response.data && response.data.data) {
          return response.data.data;
        }

        return response;
      })
      .catch(() => {});
  },
  async createCompanyToLoggedInUser({ commit }, payload) {
    if (typeof payload.logo !== 'object' && payload.logo !== '')
      delete payload.logo;

    return await axios
      .post(`/company`, getFormData(payload, POST), {
        ContentType: 'multipart/form-data',
        errorHandle: false
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          commit('createCompany', response.data.data);
          // store.dispatch('toggleNotification', {
          //   text: `${i18n.t('notification.company_created')}`,
          //   type: '#26A06D'
          // });
          // router.push(`/companies/${response.data.data.id}`);
        }

        return response;
      })
      .catch((e) => {
        if (e && e.response && e.response.data && e.response.data.errors) {
          store.dispatch(
            'setValidationErrors',
            Object.keys(e.response.data.errors)
          );
          store.dispatch('toggleNotification', {
            text: `${e.response.data.message}`,
            type: '#AE0A0A'
          });
        }
      });
  },
  async editCompanyOfLoggedInUser(commitObject, payload) {
    if (!payload['primary_address[region_id]']) {
      delete payload['primary_address[region_id]'];
      delete payload['primary_address[country_id]'];
    }
    if (typeof payload.logo !== 'object' && payload.logo !== '')
      delete payload.logo;

    return await axios
      .post(`/company/${payload.id}`, getFormData(payload, PUT), {
        ContentType: 'multipart/form-data',
        errorHandle: false
      })
      .then(async (response) => {
        if (response && response.data && response.data.data) {
          // store.dispatch('toggleNotification', {
          //   text: `${i18n.t('notification.company_edited')}`,
          //   type: '#26A06D'
          // });
          // router.push(`/companies/${response.data.data.id}`);
        }

        return response;
      })
      .catch((e) => {
        if (e && e.response && e.response.data && e.response.data.errors) {
          store.dispatch(
            'setValidationErrors',
            Object.keys(e.response.data.errors)
          );
          store.dispatch('toggleNotification', {
            text: `${e.response.data.message}`,
            type: '#AE0A0A'
          });
        }
      });
  }
};

export const mutations = {
  setCompanies(Store, { companies }) {
    this.state.companies.companies = companies;
  },
  createCompany(Store, { company }) {
    this.state.companies.companies.push(company);
  },
  setCompaniesPagination(Store, { pagination }) {
    this.state.companies.pagination = pagination;
  }
};
