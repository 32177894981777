export const state = {
  app: {
    loading: true,
    showModal: false,
    showModalSession: false,
    showNotification: false,
    notificationConfiguration: null,
    showModalShare: false,
    isMenuFixed: false
  }
};

export const getters = {
  getLoading(state) {
    return state.app.loading;
  },
  isMenuFixed(state) {
    return state.app.isMenuFixed;
  }
};
export const mutations = {
  setLoading(state, value) {
    state.app.loading = value;
  },

  setModal(state, modalConfig) {
    state.app.showModal = modalConfig ? true : false;
  },
  setModalSession(state, value) {
    state.app.showModalSession = value;
  },
  setNotification(state, notificationConfig) {
    state.app.showNotification = notificationConfig ? true : false;
    state.app.notificationConfiguration = notificationConfig
      ? { ...notificationConfig }
      : {};
  },
  showModalShare(state) {
    state.app.showModalShare = true;
  },
  hideModalShare(state) {
    state.app.showModalShare = false;
  },
  // openMenu(state) {
  //   state.app.isMenuOpen = true;
  // },
  // closeMenu(state) {
  //   state.app.isMenuOpen = false;
  // },
  toggleMenu(state) {
    state.app.isMenuFixed = !state.app.isMenuFixed;
  }
};
export const actions = {
  toggleLoading({ commit }, value) {
    commit('setLoading', value);
  },

  toggleModal({ commit }, modalConfig) {
    commit('setModal', modalConfig);
  },

  toggleModalSession({ commit }, value) {
    commit('setModalSession', value);
  },

  toggleNotification({ commit }, notificationConfig) {
    commit('setNotification', notificationConfig);
  },
  showModalShare({ commit }) {
    commit('showModalShare');
  },
  hideModalShare({ commit }) {
    commit('hideModalShare');
  },
  // openMenu({ commit }) {
  //   commit('openMenu');
  // },
  // closeMenu({ commit }) {
  //   commit('closeMenu');
  // },
  toggleMenu({ commit }) {
    commit('toggleMenu');
  }
};
