import axios from 'axios';

export const state = {
  searchResults: [],
  pagination: {},
  searchQuery: ''
};

export const getters = {
  getSearchResults(state) {
    return state.searchResults;
  },
  getSearchResultsPagination(state) {
    return state.pagination;
  },
  getSearchQuery(state) {
    return state.searchQuery;
  }
};

export const actions = {
  async fetchSearchResults({ commit }, urlParams) {
    return await axios
      .get(`search${urlParams ? '?' : ''}${urlParams ? urlParams : ''}`)
      .then((response) => {
        if (response.data) {
          const searchResults = response.data.data.map((item) => {
            return {
              ...item,
              company_name: item.company?.name,
              name_order: item.name
            };
          });
          commit('setSearchResults', {
            searchResults
          });
          commit('setSearchResultsPagination', {
            pagination: response.data.meta
          });
        }

        return response;
      })
      .catch(() => {});
  },
  async setSearchQuery({ commit }, query) {
    commit('setSearchQuery', query);
  }
};

export const mutations = {
  setSearchResults(Store, { searchResults }) {
    this.state.search.searchResults = searchResults;
  },
  setSearchResultsPagination(Store, { pagination }) {
    this.state.search.pagination = pagination;
  },
  setSearchQuery(Store, query) {
    this.state.search.searchQuery = query;
  }
};
