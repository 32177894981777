<template>
  <div id="app" :key="getSessionLanguage">
    <v-app v-if="!checkingForSession">
      <div class="main-wrap">
        <SideNavigation v-if="showNav && !$vuetify.breakpoint.smAndDown" />

        <div
          class="right-side"
          :class="
            isMenuFixed && !$vuetify.breakpoint.smAndDown ? 'nav-is-open' : ''
          "
        >
          <Header v-if="showHeader" class="header" />
          <router-view />
          <!-- <div class="sticky-foot"></div> -->
        </div>
      </div>

      <Notification v-if="$store.state.app.app.showNotification" />
      <ModalShare />
    </v-app>
  </div>
</template>

<script>
import Header from '@/components/common/Header.vue';
import Notification from '@/components/common/Notification.vue';
import ModalShare from '@/components/common/ModalShare.vue';
import SideNavigation from '@/components/common/SideNavigation.vue';
//import { mapActions } from 'vuex';
export default {
  components: {
    Header,
    // Footer,
    // Loader,
    // Modal,
    // ModalSession,
    Notification,
    ModalShare,
    SideNavigation
  },
  data() {
    return {
      checkingForSession: true
    };
  },
  watch: {
    getSessionLanguage(value) {
      this.$i18n.locale = value;
    }
  },
  computed: {
    showHeader() {
      return (
        this.$store?.getters?.identity?.isAuthenticated &&
        this.$route.name !== 'registration' &&
        this.$route.name !== 'login' &&
        this.$route.name !== 'login-sso' &&
        this.$route.name !== 'forgot-password' &&
        this.$route.name !== 'registration-confirmed' &&
        this.$route.name !== 'verification' &&
        this.$route.name !== 'screen_password_reset'
      );
    },
    showNav() {
      return (
        this.$store?.getters?.identity?.isAuthenticated &&
        this.$route.name !== 'registration' &&
        this.$route.name !== 'login' &&
        this.$route.name !== 'login-sso' &&
        this.$route.name !== 'forgot-password' &&
        this.$route.name !== 'registration-confirmed' &&
        this.$route.name !== 'verification' &&
        this.$route.name !== 'screen_password_reset'
      );
    },
    getSessionLanguage() {
      return this.$store.getters.sessionLanguage;
    },
    isMenuFixed() {
      return this.$store.getters.isMenuFixed;
    }
    // showFooter() {
    //   return (
    //     this.$route.name !== 'registration' &&
    //     this.$route.name !== 'login' &&
    //     this.$route.name !== 'registration-confirmed'
    //   );
    // },
  },
  methods: {
    // ...mapActions(['getNotifications']),
    // fetchNotifications() {
    //   this.getNotifications();
    // }
  },

  mounted() {
    const { isAuthenticated } = this.$store.getters.identity;

    if (isAuthenticated) {
      this.$store.dispatch('isActiveSession').then(() => {
        setTimeout(() => {
          this.checkingForSession = false;
        }, 100);
      });
    } else {
      this.checkingForSession = false;
    }
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed');
#app {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.35;
  text-align: left;
  color: #000;
  display: flex;
  width: 100%;
}
.v-application--wrap {
  width: 100%;
}
.sticky-foot {
  flex-grow: 1;
}

.v-application {
  display: flex;
}
.v-application--wrap {
  display: flex;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.main-wrap {
  display: flex;
  width: 100%;
  flex-grow: 1;
}
.right-side {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &.nav-is-open {
    width: calc(100% - 256px);
  }

  .header {
    flex-shrink: 1 !important;
    flex-grow: 0;
  }

  > div {
    flex-grow: 1;
    width: 100%;
  }
}
</style>
