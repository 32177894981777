import FlagEn from '@/assets/images/flag-en.png';
import FlagFr from '@/assets/images/flag-france.png';
import FlagBg from '@/assets/images/flag-bulgaria.png';

export const groupTechnologiesByCompetence = (data) => {
  const grouped = []; //contains objects with properties *competence name* and *technologies* for the competence

  const prepareCompetenceIds = data.map((item) => item.competence_id);
  const competenceIds = prepareCompetenceIds
    .filter((item, index) => prepareCompetenceIds.indexOf(item) === index)
    .reverse();

  competenceIds.forEach((id) => {
    const competenceName = data.find((item) => item.competence_id === id)
      .competence.name;

    const allTechnologiesForTheCompetence = data.filter(
      (item) => item.competence_id === id
    );

    grouped.push({
      competenceName,
      technologies: allTechnologiesForTheCompetence
    });
  });

  return grouped;
};

export const groupResourcesByCompany = (data) => {
  const grouped = []; //contains objects with properties *company* and *resources* for the company

  const prepareCompanyIds = data.map((item) => item.company_id);
  const companyIds = prepareCompanyIds.filter(
    (item, index) => prepareCompanyIds.indexOf(item) === index
  );

  companyIds.forEach((id) => {
    const company = data.find((item) => item.company_id === id).company;

    const allResourcesForTheCompany = data.filter(
      (item) => item.company_id === id
    );

    grouped.push({
      company,
      resources: allResourcesForTheCompany
    });
  });

  return grouped;
};

export const languageOptions = [
  {
    id: 'en',
    name: 'English (UK)',
    image: FlagEn
  },
  {
    id: 'fr',
    name: 'Français',
    image: FlagFr
  },
  {
    id: 'bg',
    name: 'Българсkи',
    image: FlagBg
  }
];

export const getLanguageLocaleName = (locale) => {
  switch (locale) {
    case 'en':
      return 'English (UK)';
    case 'fr':
      return 'Français';
    case 'bg':
      return 'Български';
    default:
      return 'English (UK)';
  }
};

export const getLanguageLocaleImage = (locale) => {
  switch (locale) {
    case 'en':
      return FlagEn;
    case 'fr':
      return FlagFr;
    case 'bg':
      return FlagBg;
    default:
      return FlagEn;
  }
};
