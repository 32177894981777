<template>
  <!-- $store.state.app.app.showNotification -->
  <v-dialog
    v-model="$store.state.app.app.showModalShare"
    max-width="500px"
    content-class="modal-share text-left"
    @click:outside="close"
  >
    <v-card>
      <div class="modal_head">
        <h4>Thank you for sharing</h4>

        <v-btn color="white darken-1" rounded @click="close">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </div>

      <div class="modal_body">
        <p>Please spread the word if you find this page useful.</p>

        <ul class="share-icons">
          <li>
            <v-btn
              color="primary"
              x-large
              outlined
              icon
              @click="close"
              :href="`https://twitter.com/intent/tweet?text=${tweet}&url=${link}${
                tweet !== '' ? '&via=tweet_starzdata' : ''
              }`"
              target="_blank"
            >
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
          </li>
          <li>
            <v-btn
              color="primary"
              outlined
              x-large
              icon
              @click="close"
              :href="`https://www.linkedin.com/shareArticle/?mini=true&url=${link}`"
              target="_blank"
            >
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
          </li>
          <li>
            <v-btn
              color="primary"
              outlined
              x-large
              icon
              @click="close"
              :href="`https://www.linkedin.com/shareArticle/?mini=true&url=${link}`"
              target="_blank"
            >
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
          </li>
        </ul>

        <h6 class="title-with-border">Share Url</h6>

        <v-text-field
          :value="link"
          name="sharder-link"
          readonly
          label=""
          placeholder=""
          outlined
          class="login-form"
        >
        </v-text-field>
      </div>

      <div class="modal_actions">
        <v-btn color="white darken-1" @click="close">
          <v-icon>mdi-arrow-left</v-icon>
          Go Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="copyToClipboard" class="btn-copy">
          <v-icon>mdi-clipboard-check-multiple</v-icon>
          <span>Copy URL to Clipboard</span>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalShare',

  data() {
    return {
      dialog: false
    };
  },
  computed: {
    link() {
      return window.location.origin;
    },
    tweet() {
      return 'tweet msg';
    }
  },
  methods: {
    close() {
      this.$store.dispatch('hideModalShare');
    },
    submit() {
      console.log(1);
    },
    async copyToClipboard() {
      await navigator.clipboard.writeText(this.link);
      //this.handleClose();
    }
  }
};
</script>

<style lang="scss" scoped>
.modal_head {
  background: #ffeece;
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ff6700;

  h4 {
    margin-bottom: 0;
  }
}

.modal_body {
  padding: 20px 16px 0;
  background: #fff;

  h6.title-with-border {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      background: #ff6700;
      width: 27px;
      height: 3px;
    }
  }
}

.modal_actions {
  background: #f5f5f5;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-copy {
  text-transform: none;
}
.share-icons {
  padding-left: 0;
  display: flex;
  list-style-type: none;
  margin-bottom: 20px;

  li + li {
    margin-left: 20px;
  }
}
</style>
