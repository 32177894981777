<template>
  <v-select
    :items="items"
    label=""
    v-model="selectedLanguage"
    item-text="name"
    item-value="id"
    @change="changeSessionLanguage"
    class="language-dropdown mt-0 pt-0"
    :class="$vuetify.breakpoint.xs ? 'language-dropdown-mobile' : ''"
  >
    <template v-slot:selection="{ item }">
      <div class="flag-holder">
        <img :src="item.image" width="28" height="28" />
      </div>
      <!-- v-if="!$vuetify.breakpoint.xs" -->
      <span class="language-text">
        {{ item.name }}
      </span>
    </template>

    <template v-slot:item="{ item }">
      <div class="flag-holder">
        <img :src="item.image" width="28" height="28" />
      </div>
      <span class="language-text">
        {{ item.name }}
      </span>
    </template>
  </v-select>
</template>

<script>
import {
  languageOptions,
  getLanguageLocaleName,
  getLanguageLocaleImage
} from '@/utils/helpers';
export default {
  name: 'LanguageDropdown',

  data() {
    return {
      selectedLanguage: {},
      items: languageOptions
    };
  },
  computed: {
    sessionLanguage() {
      return this.$store.getters.sessionLanguage;
    }
  },
  watch: {
    sessionLanguage(value) {
      this.selectedLanguage = {
        id: value,
        name: getLanguageLocaleName(this.sessionLanguage),
        image: getLanguageLocaleImage(this.sessionLanguage)
      };
    }
  },
  methods: {
    changeSessionLanguage(language) {
      this.$store.dispatch('setSessionLanguage', language);
      this.$i18n.locale = language;
    }
  },
  mounted() {
    this.selectedLanguage = {
      id: this.sessionLanguage,
      name: getLanguageLocaleName(this.sessionLanguage),
      image: getLanguageLocaleImage(this.sessionLanguage)
    };
  }
};
</script>

<style lang="scss" scoped>
.flag-holder {
  width: 42px;
  height: 42px;
  border: 7px solid #faf9fd;
  border-radius: 50%;
  margin-right: 4px;
  img {
    border-radius: 50px;
  }
}
.language-text {
  font-size: 14px;
  line-height: 1.71;
  color: #5e3bc2;
}
</style>

<style lang="scss">
.language-dropdown {
  .v-text-field__details {
    display: none !important;
  }
  &.v-text-field .v-input__append-inner {
    margin-top: 8px;
  }
  .language-text + input {
    width: 0;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
}

// .language-dropdown-mobile {
//  width: 70px;

//  .flag-holder + input {
//    display: none;
//  }
// }
.language-dropdown.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  display: none;
}
.language-dropdown.v-text-field.v-input--is-focused
  > .v-input__control
  > .v-input__slot:after {
  display: none;
}
.language-dropdown .theme--light.v-icon {
  color: #5e3bc2 !important;
  caret-color: !important;
}
</style>
