import axios from 'axios';
import { getFormData, PUT } from '@/utils/axios';
import store from '..';
import router from '@/router';
import { i18n } from '../../main';

export const state = {
  isAuthenticated: false,
  token: null,
  user: null,
  sessionLanguage: 'en'
};

export const getters = {
  identity(state) {
    return {
      isAuthenticated: state.isAuthenticated,
      token: state.token,

      user: state.user
    };
  },
  fullUserInfo(state) {
    return state.user;
  },
  sessionLanguage(state) {
    return state.sessionLanguage;
  }
};

export const actions = {
  sessionEnd({ commit, dispatch }, payload) {
    dispatch('toggleNotification', {
      text: `${payload.message}`,
      type: '#AE0A0A'
    });

    commit('logout');
  },
  logoutAction({ commit }) {
    commit('logout');
  },
  async authenticate(
    { commit },
    { email, password, remember_me, recaptchaToken }
  ) {
    return await axios
      .post(
        '/login',
        {
          email,
          password,
          remember_me,
          response: recaptchaToken
        },
        { errorHandle: false }
      )
      .then((response) => {
        if (response.data) {
          localStorage.setItem('token', response.data.token);

          commit('login', {
            token: response.data.token,
            user: {
              avatar: response.data.data.avatar,
              avatar_thumb: response.data.data.avatar_thumb,
              created_at: response.data.data.created_at,
              email: response.data.data.email,
              email_verified_at: response.data.data.email_verified_at,
              id: response.data.data.id,
              f_name: response.data.data.f_name,
              l_name: response.data.data.l_name,
              role: response.data.data.role,
              updated_at: response.data.data.updated_at,
              preferences: response.data.data.preferences
            }
          });

          commit(
            'setSessionLanguage',
            response.data.data.preferences?.lang || 'en'
          );
        }

        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  async register(commitObject, { f_name, l_name, email, password }) {
    return await axios
      .post(
        '/register',
        {
          f_name,
          l_name,
          email,
          password
        },
        { errorHandle: false }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
        // handle this error here
      });
  },
  async passwordForgot(commitObject, { email }) {
    return await axios
      // withing for forgoten password endpoint
      .post('/register', {
        email
      })
      .then((response) => {
        //console.log(response);

        return response;
      })
      .catch(() => {
        // handle this error here
      });
  },
  async passwordReset(commitObject, { email }) {
    return await axios
      // withing for forgoten password endpoint
      .post('/forgot-password', {
        email
      })
      .then((response) => {
        return response;
      })
      .catch(() => {
        // handle this error here
        //console.warn('Not good man :(');
      });
  },
  async updateUser({ commit, state }, payload) {
    if (!payload.password) delete payload.password;
    if (typeof payload.avatar !== 'object' && payload.avatar !== '')
      delete payload.avatar;

    return await axios
      .post(`/user/${payload.user_id}`, getFormData(payload, PUT), {
        ContentType: 'multipart/form-data',
        errorHandle: false
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          const { f_name, l_name, email, avatar, preferences } =
            response.data.data;
          const { lang, mail_notifications } = preferences;
          store.dispatch('toggleNotification', {
            text: `${i18n.t('notification.user_updated')}`,
            type: '#26A06D'
          });
          commit('updatedUser', {
            f_name,
            l_name,
            email,
            avatar,
            language: lang,
            mail_notifications
          });

          if (lang !== state.sessionLanguage) {
            store.dispatch('setSessionLanguage', lang);
          }
        }

        return response;
      })
      .catch((e) => {
        if (e && e.response && e.response.data && e.response.data.errors) {
          store.dispatch(
            'setValidationErrors',
            Object.keys(e.response.data.errors)
          );
          store.dispatch('toggleNotification', {
            text: `${e.response.data.message}`,
            type: '#AE0A0A'
          });
        }
      });
  },
  async resetPassword(
    commitObject,
    { token, email, password, password_confirmation }
  ) {
    return await axios
      .post(`/reset-password`, {
        token,
        email,
        password,
        password_confirmation
      })
      .then((response) => {
        return response;
      })
      .catch(() => {
        // handle this error here
        //console.warn('Not good man :(');
      });
  },
  async resendVerificationEmail(commitObject, { email }) {
    return await axios
      .post(`/email/verify/resend`, {
        email
      })
      .then((response) => {
        return response;
      })
      .catch(() => {
        // handle this error here
        //console.warn('Not good man :(');
      });
  },
  async setSessionLanguage({ commit }, language) {
    commit('setSessionLanguage', language);
  },
  async isActiveSession() {
    return await axios
      .get(
        `/notification?&page=1&pageSize=1`,
        {},
        {
          errorHandle: false
        }
      )
      .then((response) => {
        if (response && response.data && response.data.data) {
          return {
            error: false
          };
        } else {
          return {
            error: true
          };
        }
      })
      .catch(() => {
        return {
          error: true
        };
      });
  }
};

export const mutations = {
  async login(Store, { token, user }) {
    this.state.identity.isAuthenticated = true;
    this.state.identity.token = token;
    this.state.identity.user = user;
  },
  logout() {
    console.log(router.history.current.path);
    if (router.history.current.path !== '/login') {
      router.push('/login');
    }

    //clean all of store modules
    this.state.identity.isAuthenticated = false;
    this.state.identity.token = null;
    this.state.identity.user = null;
    this.state.identity.sessionLanguage = 'en';
    //this.$store.reset();
    this.state.formValidationErrors.validationErrors = [];
    this.state.applications.applications = [];
    this.state.applications.pagination = {};
    this.state.companies.companies = [];
    this.state.companies.pagination = {};
    this.state.competences.competences = [];
    this.state.projects.projects = [];
    this.state.projects.project = {};
    this.state.projects.pagination = {};
    this.state.team.team = [];
    this.state.team.pagination = {};
    this.state.users.users = [];
    this.state.notifications.notifications = [];
    this.state.notifications.pagination = {
      per_page: 10,
      current_page: 1
    };
    this.state.notifications.only_unread_filter = false;
    this.state.notifications.dropdownNotifications = [];
    this.state.notifications.total_unread = 0;

    window.localStorage.removeItem('SlsState');
    window.localStorage.removeItem('token');
  },
  updatedUser(
    Store,
    { f_name, l_name, email, avatar, language, mail_notifications }
  ) {
    this.state.identity.user = {
      ...this.state.identity.user,
      f_name,
      l_name,
      email,
      avatar,
      preferences: {
        ...this.state.identity.user.preferences,
        lang: language,
        mail_notifications
      }
    };
  },
  setSessionLanguage(Store, language) {
    this.state.identity.sessionLanguage = language;
  }
};
