<template>
  <div class="search" :class="$vuetify.breakpoint.xs ? 'search-mobile' : ''">
    <v-text-field
      name="main-search"
      error-messages=""
      label=""
      :value="getSearchQuery"
      :placeholder="$t('search_global')"
      outlined
      v-on:keyup.enter="submit"
      @change="handleSearchQueryChange"
      prepend-inner-icon="mdi-magnify"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'Search',
  computed: {
    getSearchQuery() {
      if (this.$route.name === 'search')
        return this.$store.getters.getSearchQuery;
      else return '';
    }
  },
  methods: {
    handleSearchQueryChange(query) {
      this.$store.dispatch('setSearchQuery', query);
    },
    submit() {
      if (this.$route.name !== 'search') {
        this.$router.push('/search');
      }
    }
  }
};
</script>

<style lang="scss">
.search {
  width: 100%;
  max-width: 546px;

  .v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot
    fieldset {
    color: #e1e4f8;
    font-size: 16px;
  }
  // fix for error message
  .v-text-field__details {
    display: none;
  }
  // fix for field height
  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 44px;
    margin-bottom: 0;
  }
  // fix for icon position
  .v-text-field .v-input__prepend-inner {
    margin-top: 10px !important;
  }

  &.search-mobile {
    max-width: 100%;
  }
  @media only screen and (min-width: 961px) and (max-width: 1200px) {
    max-width: 400px;
  }

  @media only screen and (min-width: 600px) and (max-width: 960px) {
    max-width: 100%;
  }
}
</style>
