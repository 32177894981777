<template>
  <div class="header d-flex justify-space-between align-center">
    <router-link v-if="$vuetify.breakpoint.smAndDown" to="/" class="logo">
      <img :src="appLogo" alt="" style="max-width: 136px" />
    </router-link>

    <!-- <v-btn
      v-if="isMenuFixed && $vuetify.breakpoint.sm"
      @click="toggleSideNavigation"
      class="btn-search-toggle"
      color="primary"
    >
      <v-icon>mdi-magnify</v-icon>
    </v-btn> -->
    <!-- {{ isMenuFixed && $vuetify.breakpoint.sm }}
    {{ $vuetify.breakpoint.mdAndUp }}
    {{ $vuetify.breakpoint.sm }}
 -->
    <Search v-if="!$vuetify.breakpoint.smAndDown" />
    <!--  (!$vuetify.breakpoint.xs && !isMenuFixed && $vuetify.breakpoint.sm) ||
        $vuetify.breakpoint.mdAndUp -->

    <div class="header-inner d-flex align-center">
      <div
        v-if="!$vuetify.breakpoint.smAndDown"
        class="flex-shrink-0"
        style="max-width: 200px"
      >
        <LanguageDropdown></LanguageDropdown>
      </div>

      <NotificationDropdown v-if="getLoggedUser" class="ml-4" />

      <ProfileDropdown
        v-if="getLoggedUser && !$vuetify.breakpoint.xs"
        class="ml-4"
      />

      <router-link
        v-if="!getLoggedUser && !$vuetify.breakpoint.xs"
        to="/login"
        class="d-flex align-center text-decoration-none ml-2"
      >
        <div class="image mr-2">
          <v-avatar color="#4C2E6A">
            <v-icon dark size="46"> mdi-account-circle </v-icon>
          </v-avatar>
        </div>
        {{ $t('profile_dropdown.link_sign_in') }}
      </router-link>

      <!-- Mobile Menu -->

      <v-btn
        v-if="$vuetify.breakpoint.smAndDown"
        fab
        dark
        small
        color="#4c2e6a"
        class="ml-4"
        @click="toggleNavigation"
        ><v-icon>mdi-menu</v-icon></v-btn
      >

      <div
        class="mobile-menu-holder"
        :class="isMobileNavigationVisible ? 'isVisible' : ''"
      >
        <Search />

        <LanguageDropdown></LanguageDropdown>

        <!-- <ProfileDropdown v-if="getLoggedUser" class="ml-4" /> -->

        <router-link
          v-if="!getLoggedUser && !$vuetify.breakpoint.xs"
          to="/login"
          class="d-flex align-center text-decoration-none ml-2"
        >
          <div class="image mr-2">
            <v-avatar color="#4C2E6A">
              <v-icon dark size="46"> mdi-account-circle </v-icon>
            </v-avatar>
          </div>
          Sign In
        </router-link>

        <NavigationMobile @closeMenu="closeNavigation"></NavigationMobile>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/assets/images/logo.svg';
import LanguageDropdown from '@/components/common/LanguageDropdown.vue';
import ProfileDropdown from '@/components/common/ProfileDropdown.vue';
import NotificationDropdown from '@/components/common/NotificationDropdown.vue';
import NavigationMobile from '@/components/common/NavigationMobile.vue';
import Search from '@/components/common/Search.vue';

export default {
  name: 'Header',
  components: {
    ProfileDropdown,
    LanguageDropdown,
    NotificationDropdown,
    NavigationMobile,
    Search
  },
  data() {
    return {
      appLogo: Logo,
      isMobileNavigationVisible: false
    };
  },
  computed: {
    getLoggedUser() {
      return this.$store.getters.fullUserInfo;
    },
    isMenuFixed() {
      return this.$store.getters.isMenuFixed;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logoutAction');
      //this.$router.push('login');
    },
    toggleNavigation() {
      this.isMobileNavigationVisible = !this.isMobileNavigationVisible;
    },
    closeNavigation() {
      this.isMobileNavigationVisible = false;
    },
    toggleSideNavigation() {
      this.$store.dispatch('toggleMenu');
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding: 10px 10px;
}

.mobile-menu-holder {
  position: fixed;
  top: 75px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 75px);
  background: #fff;
  z-index: 2;
  overflow-y: scroll;
  padding: 20px 20px;
  border-top: 1px solid #4c2e6a;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.4s, visibility 0.4s;

  &.isVisible {
    visibility: visible;
    opacity: 1;
  }
}
.btn-search-toggle {
  min-width: 36px !important;
  padding: 0 !important;
}
</style>
