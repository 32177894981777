import Vue from 'vue';
import Vuex from 'vuex';
import createVuexPersist from 'vuex-persistedstate';
import * as app from './modules/app';
import * as identity from './modules/identity';
import * as users from './modules/users';
import * as regionsCountries from './modules/regionsCountries';
import * as companies from './modules/companies';
import * as technologies from './modules/technologies';
import * as notifications from './modules/notifications';
import * as competences from './modules/competences';
import * as team from './modules/team';
import * as applications from './modules/applications';
import * as projects from './modules/projects';
import * as formValidationErrors from './modules/formValidationErrors';
import * as search from './modules/search';
import SecureLs from 'secure-ls';

const ls = new SecureLs({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createVuexPersist({
      key: 'SlsState',
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ],
  mutations: {
    // ping(this: Store<{ lastActivityAt: number }>) {
    //  this.state.lastActivityAt = Date.now();
    // }
  },
  modules: {
    app,
    identity,
    users,
    regionsCountries,
    companies,
    projects,
    applications,
    technologies,
    notifications,
    competences,
    team,
    formValidationErrors,
    search
  }
});
