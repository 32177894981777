<template>
  <div class="notification-wrapper">
    <v-alert
      border="top"
      :color="$store.state.app.app.notificationConfiguration.type"
      dark
      :dismissible="true"
    >
      {{ $store.state.app.app.notificationConfiguration.text }}
      <template v-slot:close="{ toggle }">
        <v-icon class="ml-2" @click="closeAlert(toggle)"
          >mdi-close-circle</v-icon
        >
      </template>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'NotificationComponent',
  data() {
    return {};
  },
  methods: {
    closeAlert(toggle) {
      toggle();
      this.$store.dispatch('toggleNotification');
    }
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('toggleNotification');
    }, 6000);
  }
};
</script>

<style scoped lang="scss">
.notification-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
}
</style>
