import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#4C2E6A',
        secondary: '#BDBDBD',
        accent: '#82B1FF',
        error: '#FB0404',
        info: '#5E3BC2',
        success: '#26A06D',
        warning: '#FF6700',
        black: '#000000',
        white: '#FFFFFF'
      }
    }
  }
};

export default new Vuetify(opts);
