export const PATCH = 'PATCH';
export const PUT = 'PUT';
export const POST = 'POST';

export function getFormData(object, methodType) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  formData.append('_method', methodType);
  return formData;
}
