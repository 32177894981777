import axios from 'axios';
//import Vue from 'vue';
import store from '../store';
import router from '../router';

window.axios = axios;

//console.log(process.env.VUE_APP_API_URL);
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    //console.log(config);
    let accessToken = localStorage.getItem('token');
    //console.log(accessToken);

    config.headers = {
      ...config.headers,
      'X-localization': store.getters.sessionLanguage,
      Accept: 'application/json'
    };

    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        'content-type': config.ContentType || 'application/json',
        'X-localization': store.getters.sessionLanguage,
        Accept: 'application/json'
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    //console.log('everything is okkkkk');
    return response;
  },
  function (error) {
    //console.log(error);
    // check for errorHandle config
    //console.log(error.response.data.message);
    if (
      error.config.hasOwnProperty('errorHandle') &&
      error.config.errorHandle === false
    ) {
      //console.log('here should go local error');
      return Promise.reject(error);
    }

    if (error.response && error.response.data.message === 'Unauthenticated.') {
      // handle what should we do when session expire and BE return that user is Unauthenticated.
      //console.log('Unauthenticated');
      store.dispatch('sessionEnd', { message: error.response.data.message });
    }

    if (error.message === 'Request failed with status code 404') {
      router.push('/404');
      return Promise.reject(error);
    }

    if (error.response && error.response.data.message !== 'Unauthenticated.') {
      //console.log(error);
      // here we should display error in toast
      store.dispatch('toggleNotification', {
        text: `${error.response.data.message}`,
        type: '#AE0A0A'
      });
    }
  }
);
