import axios from 'axios';

export const state = {
  users: [],
  totalUsersNumber: 0
};

export const getters = {
  getAllUsers(state) {
    return state.users;
  },
  getTotalUsersCount(state) {
    return state.totalUsersNumber;
  }
};

export const actions = {
  async fetchAllUsers({ commit }, urlParams) {
    return await axios
      .get(`user${urlParams ? '?' : ''}${urlParams ? urlParams : ''}`)
      .then((response) => {
        if (response.data) {
          console.log(response);

          commit('setUsers', {
            users: response.data.data
          });
          commit('setUsersNumber', {
            totalUsersNumber: response.data.meta.total
          });
        }

        return response;
      })
      .catch(() => {
        // handle this error here
        console.warn('Not good man :(');
      });
  },
  async addUser({ commit }, { name, email, password, password_confirmation }) {
    console.log(name, email, password, password_confirmation);
    return await axios
      .post('user', {
        name,
        email,
        password,
        password_confirmation
      })
      .then((response) => {
        if (response.data) {
          console.log(response);

          commit('addUser', {
            user: response.data.data
          });
          commit('incrementUsersNumber');
        }

        return response;
      })
      .catch(() => {
        // handle this error here
        console.warn('Not good man :(');
      });
  },
  async deleteUser({ commit }, user) {
    console.log(user);
    //, { errorHandle: false }
    return await axios
      .delete(`user/${user.id}`)
      .then((response) => {
        console.log(response);
        console.log('Everything is awesome.');

        commit('deleteUser', { user: user });

        return response;
      })
      .catch(() => {
        // handle this error here
        console.warn('Not good man :(');
      });
  }
};

export const mutations = {
  // async login(Store, { token, user }) {
  //   console.log(token);
  //   console.log(user);
  //   this.state.identity.isAuthenticated = true;
  //   this.state.identity.token = token;
  //   this.state.identity.user = user;
  // },

  setUsers(Store, { users }) {
    this.state.users.users = users;
  },
  setUsersNumber(Store, { totalUsersNumber }) {
    this.state.users.totalUsersNumber = totalUsersNumber;
  },
  incrementUsersNumber() {
    this.state.users.totalUsersNumber = this.state.users.totalUsersNumber + 1;
  },
  addUser(Store, { user }) {
    this.state.users.users.push(user);
  },
  deleteUser(Store, { user }) {
    const usersCopy = this.state.users.users;
    const index = usersCopy.findIndex((item) => item.id === user.id);
    console.log(index);

    usersCopy.splice(index, 1);
    this.state.users.users = usersCopy;
  }
};
