import '@mdi/font/css/materialdesignicons.css';
import '@/assets/styles/main.scss';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import VueI18n from 'vue-i18n';
import en from '../src/assets/locales/en.json';
import fr from '../src/assets/locales/fr.json';
import bg from '../src/assets/locales/bg.json';

import VueHtmlToPaper from 'vue-html-to-paper';

import '@/plugins/axios-config';

import { VueReCaptcha } from 'vue-recaptcha-v3';

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
  loaderOptions: {
    useRecaptchaNet: true
  }
});

const messages = {
  en,
  fr,
  bg
};

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: store.getters.sessionLanguage,
  messages
});

Vue.use(VueHtmlToPaper);
Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
