<template>
  <div class="profile-dropdown-parent">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div class="image" v-bind="attrs" v-on="on">
          <v-avatar
            color="#4C2E6A"
            :size="$vuetify.breakpoint.xs ? '40' : '46'"
          >
            <v-img
              v-if="getLoggedUser.avatar"
              :src="getLoggedUser.avatar"
              alt="ProfileImg"
            />
            <v-icon v-else dark size="46"> mdi-account-circle </v-icon>
          </v-avatar>
        </div>
      </template>
      <v-list class="profile-dropdown">
        <div class="group group--info">
          <h6>{{ getLoggedUser.f_name + ' ' + getLoggedUser.l_name }}</h6>
        </div>
        <div class="group group--links">
          <ul>
            <li>
              <router-link to="/account-settings">
                <v-icon>mdi-account-edit-outline</v-icon>
                <span>{{ $t('profile_dropdown.link_account_settings') }}</span>
              </router-link>
            </li>
            <li>
              <router-link to="/companies">
                <v-icon>mdi-briefcase-outline</v-icon>
                <span>{{
                  $t('profile_dropdown.link_company_information')
                }}</span>
              </router-link>
            </li>

            <li
              v-if="getLoggedUser && getLoggedUser.email_verified_at === null"
            >
              <a href="" @click.prevent="resendVerification">
                <v-icon>mdi-shield-check</v-icon>
                <span>{{
                  $t('profile_dropdown.link_resend_verification')
                }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="group group--links">
          <ul>
            <li>
              <a href="https://www.gotatech.team/privacy.html" target="_blank">
                {{ $t('profile_dropdown.link_legal_notice') }}
              </a>
            </li>
          </ul>
        </div>

        <div class="group group--links">
          <ul>
            <li>
              <a href="" @click.prevent="logout">
                <v-icon>mdi-logout</v-icon>
                <span>{{ $t('profile_dropdown.link_sign_out') }}</span>
              </a>
            </li>
          </ul>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'ProfileDropdown',

  data() {
    return {};
  },
  computed: {
    getLoggedUser() {
      return this.$store.getters.fullUserInfo;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logoutAction');
      //this.$router.push('login');
    },
    resendVerification() {
      axios
        .post('email/verify/resend', { errorHandle: false })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
          // handle this error here
          console.warn('Not good man :(');
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-dropdown {
  width: 280px;
  border: 1px solid #5e3bc2;
  border-radius: 6px;
}

.group + .group {
  border-top: 1px solid #b6b6b6;
}

.group--links {
  ul {
    list-style-type: none;
    padding-left: 0;
  }
  a {
    display: block;
    text-decoration: none;
    font-size: 15px;
    color: #000;
    line-height: 1.33;
    padding: 9px 17px;

    &:hover {
      background: #f5f6fd;
    }
  }
  i {
    margin-right: 10px;
  }
}

.group--info {
  padding: 10px 18px;
}
</style>
